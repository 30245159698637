// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { graphql } from 'gatsby'
// import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// import Carousel from 'antd/lib/carousel'
import 'antd/lib/carousel/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import { updateWebsiteState as updateWebsiteStateFx } from '../state/actions'

import AboutPageSchema from '../components/schema/about-page-schema'
import ContactPageSchema from '../components/schema/contact-page-schema'

// import ArrowLeft from '../components/svg/arrow-left'
// import ArrowRight from '../components/svg/arrow-right'

import about from '../seo/about.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'About',
  slug: '/about',
  abstract: about.text,
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'About', slug: '/about' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
// export const query = graphql`
//   query {
//     img1: file(relativePath: { eq: "book/img1.png" }) {
//       childImageSharp {
//         gatsbyImageData(
//           layout: FULL_WIDTH
//           placeholder: TRACED_SVG
//           formats: [AUTO, WEBP, AVIF]
//         )
//       }
//     }
//     img2: file(relativePath: { eq: "book/img2.png" }) {
//       childImageSharp {
//         gatsbyImageData(
//           layout: FULL_WIDTH
//           placeholder: TRACED_SVG
//           formats: [AUTO, WEBP, AVIF]
//         )
//       }
//     }
//     img3: file(relativePath: { eq: "book/img3.png" }) {
//       childImageSharp {
//         gatsbyImageData(
//           layout: FULL_WIDTH
//           placeholder: TRACED_SVG
//           formats: [AUTO, WEBP, AVIF]
//         )
//       }
//     }
//     img4: file(relativePath: { eq: "book/img4.png" }) {
//       childImageSharp {
//         gatsbyImageData(
//           layout: FULL_WIDTH
//           placeholder: TRACED_SVG
//           formats: [AUTO, WEBP, AVIF]
//         )
//       }
//     }
//     img5: file(relativePath: { eq: "book/img5.png" }) {
//       childImageSharp {
//         gatsbyImageData(
//           layout: FULL_WIDTH
//           placeholder: TRACED_SVG
//           formats: [AUTO, WEBP, AVIF]
//         )
//       }
//     }
//     img6: file(relativePath: { eq: "book/img6.png" }) {
//       childImageSharp {
//         gatsbyImageData(
//           layout: FULL_WIDTH
//           placeholder: TRACED_SVG
//           formats: [AUTO, WEBP, AVIF]
//         )
//       }
//     }
//     img7: file(relativePath: { eq: "book/img7.png" }) {
//       childImageSharp {
//         gatsbyImageData(
//           layout: FULL_WIDTH
//           placeholder: TRACED_SVG
//           formats: [AUTO, WEBP, AVIF]
//         )
//       }
//     }
//   }
// `

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({ websiteState, updateWebsiteState, ...props }) => {
  const x = true
  // const resources = [
  //   { img: props.data.img1 },
  //   { img: props.data.img2 },
  //   { img: props.data.img3 },
  //   { img: props.data.img4 },
  //   { img: props.data.img5 },
  //   { img: props.data.img6 },
  //   { img: props.data.img7 },
  // ]

  // const sliderConf = {
  //   infinite: false,
  //   slidesToScroll: 1,
  //   dots: true,
  //   arrows: true,
  //   prevArrow: <ArrowLeft />,
  //   nextArrow: <ArrowRight />,
  //   customPaging: (i) => {
  //     return (
  //       <a aria-label={`Go to slide ${i + 1}`} role="link">
  //         <GatsbyImage image={getImage(resources[i].img)} />
  //       </a>
  //     )
  //   },
  //   dotPosition: 'bottom',
  // }

  return (
    <StandardPageWrapper pageSchema={pageSchema} {...props}>
      <AboutPageSchema />
      <ContactPageSchema />
      <h1>About this book</h1>
      {/*<div className="slider fullpage book-slider flow">
        <Carousel {...sliderConf}>
          {resources.map((resource) => (
            <GatsbyImage image={getImage(resource.img)} />
          ))}
        </Carousel>
      </div>*/}
      <p>
        <em>Sri Aurobindo’s Savitri: The Auroville Radio Talks</em> is a four
        volume work that emerges from talks recorded by Loretta Shartsis for
        Auroville Radio over the last seven years.
      </p>
      <h2>About Integral Yoga Talks</h2>
      <p>
        This book is part of the Integral Yoga Talks project. The Integral Yoga
        Talks project is a website offering which will publish talks by Loretta
        Shartsis on Sri Aurobindo and Mother, both in audio and video, and will
        also make available Loretta’s book compilations as web-books. The
        project is scheduled to be released in stages from 2022–24 under the
        umbrella of Prisma’s new publications format of gift-economy publishing.
      </p>
      <p>
        Over the years, Loretta has given public talks on Sri Aurobindo and
        Mother both in Auroville and abroad, and has recorded two series of
        talks for Auroville Radio. She has published four book-compilations of
        their works. These on-going efforts will now be available worldwide on
        the website{' '}
        <Link to="https://www.integral-yoga-talks.com/en/">
          www.integral-yoga-talks.com
        </Link>
        .
      </p>
      <p>
        The first stage of presentation will be the talks on Sri
        Aurobindo’s&nbsp;
        <em>Savitri</em>, with other stages to follow, culminating in the full
        website to be released on August 15, 2022, in honor of Sri Aurobindo’s
        150th Centenary.
      </p>
      <p>
        Loretta’s talks on Savitri began in 2015 when she was asked to speak
        about Sri Aurobindo and Mother on the Radio, she chose two specific
        subjects from the volumes of their Collected Works; Savitri by Sri
        Aurobindo and Mother’s Questions and Answers, the classes to the Ashram
        School children in the 1950’s.
      </p>
      <p>
        Continuing to today, Auroville Radio has published the Savitri talks
        from Book I up to the end of Book X.
      </p>
      <p>
        Mother told the Ashramites not to explain Savitri with their own
        understanding. She told them to find other things that Sri Aurobindo
        wrote on the same subjects to explain what Sri Aurobindo said in
        Savitri. Loretta begins her talks by giving the context; reviewing what
        has gone before. Then she gives the new part of the story and adds
        things from Sri Aurobindo’s other books and Mother’s books. Finally she
        reads the part of Savitri under discussion.
      </p>
      <p>
        The next stage of publication will be the talks on Mother’s Questions
        and Answers, scheduled for release on February 21, 2022, in honor of
        Mother’s birthday.
      </p>
      <h2>About Loretta Shartsis</h2>
      <p>
        Loretta Shartsis came to the Sri Aurobindo Ashram in February 1972,
        because she wanted to meet Mother. It was Sri Aurobindo’s 100th
        Centenary year. Loretta met Mother in March and the next day, without
        knowing how it happened, she found herself living at the centre of
        Auroville, with her own room in the Matrimandir Worker’s Camp. From that
        time, her life has been devoted to Mother and Sri Aurobindo. Fifty years
        later, for Sri Aurobindo’s 150th Birth Centenary, the world will have
        the results of her dedicated teaching about Mother and Sri Aurobindo.
      </p>
      <p>
        Loretta came to Mother after growing up with a life of work and
        education as a visual artist. Later she became an attorney, doing
        Supreme Court and Court of Appeals Research and Presentation and court
        trials. In her teaching work she combines a life of aesthetic pursuits
        and the high quality research and public presentation required in a
        court of law.
      </p>
      <h2>Web book, this format</h2>
      <p>
        <i>{about.altTitle}</i>&nbsp;is published as part of an emerging method
        of publishing being developed by Prisma Books. The idea is to deliver
        the high level of writing and design quality that you would find in a
        printed book, and to use advanced web technologies for publishing. This
        is the "web-book" format.
      </p>
      <p>
        <Link to="https://books.prisma.haus/en/web-books">
          Read more about web books →
        </Link>
      </p>
      <h2 id="on-gift-economy">On gift economy</h2>
      <p>
        You can buy the paperback version of the book <i>{about.altTitle}</i> at
        a fixed price. The author and the publisher have also chosen to offer
        the book to read online without a pay-wall or advertisement. This is not
        for free, but available on a Gift-Economy and Pay-What-Feels-Right
        basis. It is an invitation to give what feels right for the value you've
        received from the book and/or the support you want to give us to
        continue doing our work. Publishing in this manner is an experiment in
        abundance where we trust that when we give, we will also receive. We
        want you to enjoy the book, learn from the book, but also support the
        book. If it was worth your time, please consider buying the book or
        contributing.
      </p>
      <p>
        <Link to="https://books.prisma.haus/en/available-on-gift-economy">
          Learn more about publishing on Gift-Economy →
        </Link>
      </p>
      <h2>Prisma Books, the publisher</h2>
      <p>
        Since 1988 Prisma Books of Auroville publishes good old-fashioned,
        beautifully curated books. Prisma Books offers full publishing services
        in design, editorial, printing, artwork, illustrations, photography,
        digital media, exhibition and curatorship.
      </p>
      <p>
        <Link to="https://www.prisma.haus/en/">Prisma website →</Link>
      </p>
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    websiteState: state.websiteState,
  }),
  (dispatch) => ({
    updateWebsiteState(payload) {
      dispatch(updateWebsiteStateFx(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
